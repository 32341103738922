<template>
  <MainLayout :title="$t('explore')" :short-title="$t('staff.scholarship')" :has-mobile-menu="true" back-to="/">
    <template v-slot:menu>
      <ExploreMenuTypes />
    </template>
    <template v-slot:content>
      <div id="applications" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>{{ $t('staff.scholarship') }}</h2>
          </div>
          <div class="panel-header-nav hidden">
            <multiselect
              v-model="selectedOrganicUnit"
              :options="organicUnits"
              :show-labels="false"
              placeholder="Filtrar por unidade"
              @search-change="getOrganicUnits"
            >
              <span slot="noOptions"></span>
            </multiselect>
          </div>
        </div>
        <div class="panel-body">
          <b-tabs v-model="activeTab" :animated="false" @input="loadProceduresData">
            <b-tab-item :label="$t('procedure.status.open')" :class="{ 'is-blur': loading }">
              <RecordProcedure v-for="item in procedures" :key="item.id" :item="item" />
              <div v-if="!loading && !procedures.length" class="hero hero-for-empty">
                <div class="hero-content">
                  {{ $t('procedure.noneOpen') }}
                </div>
              </div>
            </b-tab-item>
            <b-tab-item :label="$t('procedure.status.progress')" :class="{ 'is-blur': loading }">
              <div v-if="!loading && !procedures.length" class="hero hero-for-empty">
                <div class="hero-content">
                  <p>
                    {{ $t('procedure.noneProgress') }}
                  </p>
                  <a href="https://www.uc.pt/drh/rm/pconcursais" target="_blank" class="has-margin-top">
                    <faicon icon="external-link-alt" /> {{ $t('procedure.institutionalWebsite') }}
                  </a>
                </div>
              </div>
              <RecordProcedure v-for="item in procedures" :key="item.id" :item="item" />
            </b-tab-item>
            <b-tab-item :label="$t('procedure.status.closed')" :class="{ 'is-blur': loading }">
              <div v-if="!loading && !procedures.length" class="hero hero-for-empty">
                <div class="hero-content">
                  <p>
                    {{ $t('procedure.noneClosed') }}
                  </p>
                  <a href="https://www.uc.pt/drh/rm/pconcursais" target="_blank" class="has-margin-top">
                    <faicon icon="external-link-alt" /> {{ $t('procedure.institutionalWebsite') }}
                  </a>
                </div>
              </div>
              <RecordProcedure v-for="item in procedures" :key="item.id" :item="item" />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import debounce from 'lodash/debounce'

import Multiselect from 'vue-multiselect'

import ProcedureService from '@/services/ProcedureService'
import UtilitiesService from '@/services/UtilitiesService'

import MainLayout from '@/components/layouts/MainLayout'
import ExploreMenuTypes from '@/components/menus/ExploreMenuTypes'

import RecordProcedure from '@/components/records/RecordProcedure'

export default {
  components: {
    MainLayout,
    Multiselect,
    RecordProcedure,
    ExploreMenuTypes,
  },
  data() {
    return {
      procedures: [],
      organicUnits: [],
      selectedOrganicUnit: null,
      activeTab: null,
      loading: false,
      tabStatusKey: 'published',
    }
  },
  mounted() {
    this.getProcedures()
  },
  methods: {
    // Obtem os procedimentos com um pequeno desfazamento
    loadProceduresData() {
      this.loading = true
      this.getDebouncedProcedures()
    },

    getDebouncedProcedures: debounce(function() {
      this.getProcedures()
    }, 300),

    async getProcedures() {
      // Cria mapeamento dos estados pelos separadores
      const statusMap = {
        0: 'open',
        1: 'ongoing',
        2: 'ended',
      }
      this.tabStatusKey = statusMap[this.activeTab || 0]

      const result = await ProcedureService.getApply2Procedures('scholarships', this.tabStatusKey)

      const procedures = result.data ? result.data.procedures : []

      this.procedures = []
      procedures.forEach(procedure => {
        if (procedure.publish_end) this.procedures.push(procedure)
      })

      this.loading = false
    },

    // Lista de unidades orgânicas para apresentar na dropdown de filtragem
    async getOrganicUnits() {
      this.organicUnits = await UtilitiesService.getOrganicUnits()
    },
  },
}
</script>
